import Vue from 'vue';
import Vuex from 'vuex';

import { postLogin, postLogout } from '@/api/auth-api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userGuid: null,
    username: null,
    fullName: 'Anonymous',
    isLoggedIn: false,
    userRoles: [],
    xhrRequestRunning: false,
    lastRoute: null,
    breadcrumbGroup: null,
    breadcrumbGroupRoute: null,
    breadcrumbItem: null,
  },
  mutations: {
    mark_xhr_request_running(state) {
      state.xhrRequestRunning = true;
    },

    unmark_xhr_request_running(state) {
      state.xhrRequestRunning = false;
    },

    setLastRoute(state, lastRoute) {
      state.lastRoute = lastRoute;
    },

    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    setUserGuid(state, userGuid) {
      state.userGuid = userGuid;
    },

    setUsername(state, username) {
      state.username = username;
    },

    setFullName(state, fullName) {
      state.fullName = fullName;
    },

    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },

    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbGroup = breadcrumbs.breadcrumbGroup || null;
      state.breadcrumbGroupRoute = breadcrumbs.breadcrumbGroupRoute || null;
      state.breadcrumbItem = breadcrumbs.breadcrumbItem || null;
    },
  },
  actions: {
    mark_xhr_request_running() {
      this.commit('mark_xhr_request_running');
    },

    unmark_xhr_request_running() {
      this.commit('unmark_xhr_request_running');
    },

    setBreadcrumbs(_, { route }) {
      this.commit('setBreadcrumbs', {
        breadcrumbGroup: route?.meta?.breadcrumbGroup,
        breadcrumbGroupRoute: route?.meta?.breadcrumbGroupRoute,
        breadcrumbItem: route?.meta?.breadcrumbItem,
      });
    },

    async performLogout({ dispatch, commit }) {
      dispatch('mark_xhr_request_running');

      const response = await postLogout();

      if (response) {
        commit('setIsLoggedIn', false);
        commit('setUserGuid', null);
        commit('setUsername', null);
        commit('setFullName', 'Anonymous');
        commit('setUserRoles', []);
      }

      dispatch('unmark_xhr_request_running');

      return response;
    },

    async performLogin(_, { username, password }) {
      this.dispatch('mark_xhr_request_running');

      try {
        const response = await postLogin({ username, password });

        const isLoggedIn = response && Array.isArray(response.roles);

        this.commit('setIsLoggedIn', isLoggedIn);
        this.commit('setUserGuid', isLoggedIn && response.userGuid);
        this.commit('setUsername', isLoggedIn && username);
        this.commit('setFullName', isLoggedIn && response.fullName);
        this.commit('setUserRoles', isLoggedIn ? response.roles : []);

        return isLoggedIn;
      } finally {
        this.dispatch('unmark_xhr_request_running');
      }
    },
  },
  modules: {
  },
});
